
import { defineComponent } from "vue";
import Report from '@/views/Reports/Components/ReportSales.vue';
import HomeView from "./HomeView.vue";

export default defineComponent({
  name: "Home",
  components: {
    Report,
    HomeView
  }
});
